import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  Divider,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";

import Headroom from "react-headroom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useLocation, Link } from "react-router-dom";
import TabPanel from "@mui/lab/TabPanel";
import Demographics from "./Demographics";
import Forms from "./Forms";
import PatientNote from "./PatientNote";
import HealthHistory from "./HealthHistory";
import Documents from "./Documents";
import Authorization1 from "./Authorization/Authorization";


import Breadcrumbs from "../components/Common/Breadcrumb";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import VOBPAGE from "../Pages/Vobpage";
import VOBPAGE1 from "./Vobpage1";
import OSAPAGE from "../Pages/Osapage";
import Letter from "../Pages/Letter";
import Prior_auth from "../Pages/Prior_auth";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DialogTitle from '@mui/material/DialogTitle';
import Disclaimer from "./Disclaimer";
import Notification from "./Notification";


import { KayakingRounded } from "@mui/icons-material";
// import Order from './Order';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '@mui/material/Card';
import DentistUse from "./DentistUse";
import Claim from "./Claim";
import Appointments from "./Appointments";

const PatientRegistration = (props) => {
  console.log(props,'prop main')
  document.title = "Patients | LuxBillingEHR";

  const navigate = useNavigate();
  const location = useLocation();
  const pid = props.pid
  const handleClosepatientreg= props.callingFrom == 'Authorization'?props.handleClosepatientreg:''
  const Authorization =props.callingFrom == 'Authorization'
  console.log('Authorization', Authorization)
  const patientId = props.callingFrom == 'Authorization' ? props.pid : location.state.patientId;
  const [open, setOpen] = React.useState(false);
  console.log(patientId,',,,')
  const [osapage, setOsapage] = React.useState(false);
  const [OpenLetter, setOpenLetter] = React.useState(false);
  const [OpenpriorAuth, setOpenpriorAuth] = React.useState(false);
  const [OpenDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [vobpage, setVobpage] = React.useState(false);
  const [vobpagepatientid, setVobpagepatientid] = useState();
  const [vobpage1, setVobpage1] = React.useState(false);
  const [patientName, setPatientName] = useState(false);
  const [dob, setdob] = useState(false);
  const [email, setemail] = useState(false);
  const [patid, setPatId] = useState('')
  const [dOB, setDOB] = React.useState(Moment().format('YYYY-MM-DD'))
  const [opendentist, setOpenDentist] = React.useState(false);
  const [dOBprops, setdOBprops] = useState('')
  const [dt, setDate] = useState('')
  console.log(dt,',,,,,,11')
console.log(dOBprops,',,,,,,,,')
   // API Authanticatiion

   const token = localStorage.getItem('Token')
   const auth = `Bearer ${token}`
   const headers = {
     Authorization: auth
   }
  // const openpatientName = () => {
  //   setPatientName(true);
  // };
  const openvobpage = () => {
    setVobpage(true);
    // reset()
  };
  const openvobpage1 = () => {
    setVobpage1(true);
    // setPatId(patientId)
    // reset()
  };
  const openosapage = () => {
    setPatId(patientId);
    setOsapage(true);
    // reset()
  };
  const openLetter = () => {
    setPatId(patientId)
    setOpenLetter(true);
    // reset()
  };
  const openpriorAuth = () => {
    setPatId(patientId)
    setOpenpriorAuth(true);
    // reset()
  };
  const opendisclaimer = () => {
    setOpenDisclaimer(true);
    // reset()
  };
  const handleopendentist = () => {
    setOpenDentist(true);
    // reset()
  };


  const closevobpage = () => {
    setVobpage(false);
    // reset()
  };
  const closevobpage1 = () => {
    setVobpage1(false);
    // reset()
  };
  const closeosapage = () => {
    setOsapage(false);
    // reset()
  };
  const closeLetter = () => {
    setOpenLetter(false);
    // reset()
  };

  const closepriorAuth = () => {
    setOpenpriorAuth(false);
    // reset()
  };  
  const closeDisclaimer = () => {
    setOpenDisclaimer(false);
    // reset()
  };
  const handleclosedentist = () => {
    setOpenDentist(false);
    // reset()
  };
  // const closepatientName = () => {
  //   setPatientName(false);
  //   // reset()
  // };
  const [Patientinfo, setPatientinfo] = useState([])
  const [Namee, setNamee] = useState([])

  useEffect(() => {
    if(patientId>0)
    {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Patients/getPatientInfo?patientid=${props.callingFrom == 'Pateint' ? pid : patientId}`,
        { headers }
      )
      .then(response => {
        console.log('response.data: ', response)
        setPatientinfo(response)
        setNamee(response.patientName)
        var CreatedDate = Moment(response.dOB).format('YYYY-MM-DD')
        setDOB(CreatedDate)


      })

      .catch(error => {
        console.error('There was an error!', error)
      })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])

  const options = [
    <div>
      <Grid container>
        <Grid>
          <Button className="Button" onClick={openvobpage} variant="contained">
            VOB CheckList
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openvobpage1} variant="contained">
            Auth-Request
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openosapage} variant="contained">
            Screener
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openLetter} variant="contained">
            Letter
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openpriorAuth} variant="contained">
            Prior Auth
          </Button>
        </Grid>
       <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={opendisclaimer} variant="contained">
            Disclaimer
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={handleopendentist} variant="contained">
            Dentist Use
          </Button>
        </Grid>
       
      </Grid>
    </div>,
  ];
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openmaxheight = Boolean(anchorEl);
  const handleClickmaxheight = (event) => {
    setAnchorEl(event.currentTarget);
    setVobpagepatientid(patientId)


  };
  const handleClosemaxheight = () => {
    setAnchorEl(null);
    setAnchorEl(null);
  };
  const AuthPracticeId=localStorage.getItem('default_paractice')
  
  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "auto",
    overflows: "hide",
    // zIndex: "10",
  };
  const style1 = {
    backgroundColor: "#3C7F6B",
    borderRadius: ".7rem!important",
    height: '100%',
    padding: '1rem',
    textAlign: 'center'


  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs breadcrumbItem="Patient Registrtion" /> */}
          {patientId !== undefined && patientId > 0 ? (
            <Box sx={style1}>
              <Grid container>
              
              <Grid item xs={12} sm={6} md={6} lg={1.5} >
                  <Typography style={{ color: 'white',fontWeight:'bold' }}>ID: <span style={{fontWeight:'normal'}}>{patientId}</span></Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2.5}>
                  <Typography style={{ color: 'white',fontWeight:'bold' }}>Name: <span style={{fontWeight:'normal'}}>{Patientinfo.patientName}</span></Typography>
                 
                </Grid>
                
                <Grid item xs={12} sm={6} md={6} lg={2.5} >
                  <Typography style={{ color: 'white',fontWeight:'bold' }}>DOB:<span style={{fontWeight:'normal'}}>{Patientinfo.dOB}</span></Typography>
                 
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2.5} >
                  <Typography style={{ color: 'white',fontWeight:'bold'}}>Gender: <span style={{fontWeight:'normal'}}>{Patientinfo.gender}</span></Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                  <Typography style={{ color: 'white',fontWeight:'bold'}}>Balance: <span style={{fontWeight:'normal'}}>${Patientinfo.toatalbalance}</span></Typography>
                </Grid>
                
              </Grid>

            </Box>
          ) : (
            <></>
          )}

          <Modal
            open={vobpage}
            onClose={closevobpage}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '3rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        textAlign: 'center',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',

                        }}
                      >
                        DME VOB/ Prior-auth checklist (E0486)
                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={closevobpage}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        // marginLeft: 'auto',
                        marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
              <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                <VOBPAGE PatientId={vobpagepatientid} style={{ height: '30vh' }} closevobpage={closevobpage}></VOBPAGE>
              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
              />
            </Box>
          </Modal>
          <Modal
            open={vobpage1}
            onClose={closevobpage1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: "underline",

                          }}
                        >
                          VOB/ Prior-auth Request
                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={closevobpage1}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>

                <Grid>
              
             
                  <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                    <VOBPAGE1 callingFrom="Patients"  AuthPracticeId={AuthPracticeId} PatientId={patientId} PatientName={Patientinfo.patientName} DOB={dOB} Address={Patientinfo.address} closevobpage1={closevobpage1} style={{ height: '30vh' }}></VOBPAGE1>
                  </Card>
                  <ToastContainer
                    position="top-center"
                    autoClose={1500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    theme="light"
                  />
                </Grid>
              </Headroom>
            </Box>
          </Modal>
          <Modal
            open={osapage}
            onClose={closeosapage}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline'

                          }}
                        >
                          Snoring and obstructive sleep apnea (OSA) Screener{" "}

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={closeosapage}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                <OSAPAGE callingFrom="Pateint" patientId={patientId} PatientName={Patientinfo.patientName} formid={patientId} DOB={dOB} Address={Patientinfo.address} closeosapage={closeosapage} style={{ height: '30vh' }}></OSAPAGE>
                <ToastContainer
                  position="top-center"
                  autoClose={1500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  theme="light"
                />
              </Card>
            </Box>
          </Modal>

          <Modal
            open={OpenLetter}
            onClose={closeLetter}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline'

                          }}
                        >
                          LETTER OF MEDICAL NECESSITY

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={closeLetter}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                <Letter callingFrom="Patients" patientId={patientId} PatientName={Patientinfo.patientName} formid={patientId} DOB={dOB} Address={Patientinfo.address} closeLetter={closeLetter} style={{ height: '30vh' }}></Letter>
              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
              />
            </Box>
          </Modal>

          <Modal
            open={OpenpriorAuth}
            onClose={closepriorAuth}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline'

                          }}
                        >
                          Prior-auth & VOB

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={closepriorAuth}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                <Prior_auth getData1={setDate} callingFrom="Pateint" patientid={patientId} PatientName={Patientinfo.patientName} DOB={dOB} Address={Patientinfo.address} style={{ height: '30vh' }} closepriorAuth={closepriorAuth} ></Prior_auth>
              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
              />
            </Box>
          </Modal>


          <Modal
            open={OpenDisclaimer}
            onClose={closeDisclaimer}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline'

                          }}
                        >
                          Prior-auth & VOB

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={closeDisclaimer}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                <Disclaimer callingFrom="Pateint" patID={patientId} PatientName={Patientinfo.patientName} DOB={dOB} Address={Patientinfo.address} style={{ height: '30vh' }} closeDisclaimer={closeDisclaimer} ></Disclaimer>
              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
              />
            </Box>
          </Modal>

          <Modal
            open={opendentist}
            onClose={handleclosedentist}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline'

                          }}
                        >
                          Dentist Use Only

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleclosedentist}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                {/* <Disclaimer callingFrom="Pateint" patID={patientId} PatientName={Patientinfo.patientName} DOB={dOB} Address={Patientinfo.address} style={{ height: '30vh' }} closeDisclaimer={closeDisclaimer} ></Disclaimer> */}
                <DentistUse callingFrom="Pateint" patID={patientId} PatientName={Patientinfo.patientName} DOB={dOB} Address={Patientinfo.address} style={{ height: '30vh' }} handleclosedentist={handleclosedentist} ></DentistUse>
              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
              />
            </Box>
          </Modal>

          {patientId !== undefined && patientId > 0 ? (
            <Grid container>
              <Grid item xs={12}>
                <div style={{ float: "right", marginTop: '5px' }}>
                  {/* <Button
                    className="Button"
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClickmaxheight}
                    style={{ fontWeight: "bold", color: 'white' }}
                  >
                    Forms
                  </Button> */}
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={openmaxheight}
                    onClose={handleClosemaxheight}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClosemaxheight}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="lab API tabs example"
              >
                <Tab value={0} label="Demographics" />
                {patientId !== undefined && patientId > 0 ? (
                  <Tab value={1} label="Documents/ Images" />
                ) : (
                  <></>
                )}
                {/* {patientId !== undefined && patientId > 0 ? (
                  <Tab value={2} label="Forms" />
                ) : (
                  <></>
                )} */}
                {patientId !== undefined && patientId > 0 ? (
                  <Tab value={3} label="Authorization" />
                ) : (
                  <></>
                )}
                {patientId !== undefined && patientId > 0 ? (
                  <Tab value={4} label="Progress note/SOAP note" />
                ) : (
                  <></>
                )}
                  {patientId !== undefined && patientId > 0 ? (
                  <Tab value={7} label="Appointments" />
                ) : (
                  <></>
                )}
                {patientId !== undefined && patientId > 0 ? (
                  <Tab value={5} label="Claim" />
                ) : (
                  <></>
                )}
                 {patientId !== undefined && patientId > 0 ? (
                  <Tab value={6} label="Messages" />
                ) : (
                  <></>
                )}
                {/* {patientId !== undefined && patientId > 0 ? (
                  <Tab value={5} label="Health History" />
                ) : (
                  <></>
                )} */}
               

              </TabList>
            </Box>
            <TabPanel value={0}>
            <Demographics callingFrom ={Authorization} handleClosepatientreg={handleClosepatientreg}   patientid={patientId} ></Demographics>
            </TabPanel>
            <TabPanel value={1}>
            <Documents patientid={patientId} callingFrom ={"test"}  ></Documents>
            </TabPanel>
            <TabPanel value={2}>
              <Forms PatientId={patientId}></Forms>
            </TabPanel>
            <TabPanel value={4}>
            <PatientNote PatientId={patientId} ></PatientNote>
            </TabPanel>
            {/* <TabPanel value={5}>
              <HealthHistory PatientId={patientId}></HealthHistory>
            </TabPanel> */}
            <TabPanel value={3}>
            <Authorization1  PatientId={patientId} FormCall ={"demographic"}  PatientName={Patientinfo.patientName} getData1={setdOBprops} Address={Patientinfo.address} ></Authorization1>
            {/* <Authorization PatientId={patientId} PatientName={Patientinfo.patientName}></Authorization> */}
            {/* <Authorization    PatientId={patientId}
               PatientName={Patientinfo.patientName} getData1={setdOBprops} Address={Patientinfo.address}
               ></Authorization> */}
            </TabPanel>
            <TabPanel value={5}>
              <Claim PatientId={patientId} Namee={Namee} PatientName={Patientinfo.patientName}></Claim>
            
            </TabPanel>
            <TabPanel value={6}>
              <Notification PatientId={patientId}></Notification>
              {/* <Claim PatientId={patientId} PatientName={Patientinfo.patientName}></Claim> */}
            
            </TabPanel>
            <TabPanel value={7}>
            <Appointments patientId={patientId} PatientName={Patientinfo.patientName} ></Appointments>
            </TabPanel>
            
          </TabContext>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PatientRegistration;
